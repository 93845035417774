import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RegCard from "../components/RegistrationCard"
import { BIG, VVN, KLACHT, KIWA } from "../images/registraties"

import "./over-mij.scss"

const Verwijzers = () => (
  <Layout>
    <SEO title="Verwijzers" />
    <h1>Voor verwijzers</h1>
    <p>
      Als sociaal psychiatrische verpleegkundige / professional organizer ben ik
      als ZZP-er in te zetten. Ik verleen gespecialiseerde hulp, begeleiding of
      behandeling aan mensen met psychische problemen of psychiatrische
      aandoeningen, die vaak ook te kampen hebben met allerlei andere
      moeilijkheden op uiteenlopende levensgebieden.
    </p>
    <p>
      De doelgroep is cliënten met psychische of psychiatrische problematiek,
      bijvoorbeeld cliënten die kampen met ADHD, persoonlijkheidsproblematiek,
      bipolaire stoornis, cognitieve achteruitgang of verzamelstoornis. Ik werk
      met (jong-)volwassenen en ouderen.
    </p>
    <p>
      Ik heb brede kennis van de sociale kaart en ben gewend om de samenwerking
      aan te gaan met andere instanties. Mijn werkwijze is erop gericht om de
      cliënt in zo'n kort mogelijke tijd inzicht te geven in zijn klachten en
      problemen en handvatten te bieden om de regie over zijn eigen situatie
      weer terug te krijgen.
    </p>
    <p>
      Balans in hoofd en huis heeft WMO-contracten met de gemeenten Lochem,
      Zutphen, Apeldoorn, Voorst en Brummen. In deze gemeenten biedt Balans in
      hoofd en huis gespecialiseerde individuele begeleiding via Zorg in Natura.
    </p>

    <h2>Aanbod</h2>

    <p>
      Het aanbod dat ik kan bieden is divers; ik kijk naar alle levensgebieden
      en ga pragmatisch te werk. De sociale context van het probleem/hulpvraag
      van de client is een belangrijk uitgangspunt in mijn werkwijze. De
      begeleiding die ik bied is in de thuissituatie. Daarnaast is er contact
      via app/telefoon. De laagdrempeligheid in contact, maakt dat ik een
      betrouwbare schakel ben; voor cliënten en hun verwijzers. Voorbeelden van
      het aanbod:
    </p>

    <ul className="position-outside">
      <li>
        Gedragstherapeutische werkbare doelen opstellen om zo gewenst gedrag te
        kunnen aanleren (bijv. bij gewoontes die chaos veroorzaken)
      </li>
      <li>Begeleidingsgesprekken gericht op client en zijn/haar systeem</li>
      <li>
        Cognitieve training gericht op ordening en planning, handvatten bieden
        en zo mogelijk samen werken met client en het systeem
      </li>
      <li>Ondersteuning bij het verwerken van ingrijpende gebeurtenissen</li>
      <li>
        Hulp bij het aanbrengen en behouden van structuur, dit kan d.m.v.
        dagindeling, dagprogramma's en agendabeheer
      </li>
      <li>
        Psycho-educatie: informatie geven over psychische klachten, zodat de
        client meer kennis en inzicht krijgt en daardoor beter met zijn/haar
        klachten kan omgaan
      </li>
      <li>Het vinden en behouden van balans tussen draagkracht en draaglast</li>
    </ul>

    <h2>Registraties</h2>

    <div className="card-columns">
      <RegCard img={VVN} name="V&VN" href="https://www.venvn.nl/" />
      <RegCard
        img={BIG}
        name="BIG"
        href="https://zoeken.bigregister.nl/bignummer?bignummer=9010171330"
      />
      <RegCard
        img={KLACHT}
        name="Klachtenportaal ZORG"
        href="https://klachtenportaalzorg.nl/"
      />
      <RegCard img={KIWA} name="KIWA gecertificeerd" />
    </div>
  </Layout>
)

export default Verwijzers
