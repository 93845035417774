import React from "react"

export default function RegCard({ img, name, href }) {
  return (
    <a href={href} title={`Website ${name}`} target="_">
      <div className="card p-3">
        <img src={img} className="card-img-top" alt={`Logo ${name}`} />
      </div>
    </a>
  )
}
